<template>
  <div class="group-members">
    <div class="d-flex align-items-center card-wrapper">
      <div class="d-flex w-50 breadcrumb-wrapper pl-2 justify-content-start">
        <BreadCrumb :bread-crumb-items="breadCrumbItems" />
      </div>
      <div class="d-flex w-50 font-weight-bold button-style justify-content-end button-wrapper">
        <div
          v-if="getSelectedGroupMember.role===MemberType.OWNER"
          class="mr-2"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover
            class="buttons"
            variant="primary"
            :disabled="!canAddMember || spinner || !isActive"
            @click="editGroupDetail()"
          >
            Edit Business
          </b-button>
        </div>
        <CreateGroupModal
          ref="create-group-modal"
          :edit-group-modal="editGroupModal"
        />
        <b-tooltip
          v-if="!isActive"
          ref="tooltip"
          :disabled.sync="isActive"
          target="tooltip-button-disable"
        >
          <span>Business subscription is expired!</span>
        </b-tooltip>
        <b-tooltip
          v-if="!canAddMember"
          ref="tooltip"
          :disabled.sync="canAddMember"
          target="tooltip-button-disable"
        >
          <span>Add Member Limit is {{ getQuotaOfMembersInSelectedGroup }} Only</span>
        </b-tooltip>
        <div id="tooltip-button-disable">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover
            variant="primary"
            class="buttons"
            :disabled="!canAddMember || spinner || !isActive"
            @click="addMemberToGroup()"
          >
            Add Member
          </b-button>
        </div>
        <b-button
          v-if="!isAgency"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover
          variant="primary"
          class="ml-2 buttons"
          :disabled="spinner || isAddFeatureDisabled"
          @click="$router.push(
            `/edit-packages/${selectedGroupId}`
          )"
        >
          Change Subscription
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover
          variant="primary"
          class="ml-2 buttons"
          font-size="lg"
          :disabled="spinner"
          @click="cancelSubscription"
        >
          Cancel Subscription
        </b-button>
        <AddGroupMember
          ref="add-member-modal"
          @getAllMembersOfGroup="getAllMembersOfGroup"
        />
        <UpdatePermissionsOfMember
          ref="update-member-modal"
          :update-member="updateMember"
          @getAllMembersOfGroup="getAllMembersOfGroup"
        />
      </div>
    </div>
    <div
      v-if="!spinner"
      class="d-flex justify-content-end button-style mt-1"
    >
      <span class="font-weight-bolder">Sub-Members : {{ allMembers.length-1 }} / {{ getQuotaOfMembersInSelectedGroup }}</span>
    </div>
    <b-card
      no-body
      style="margin: 2rem"
    >
      <b-skeleton-table
        v-if="spinner"
        :rows="5"
        :columns="5"
        :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
      />
      <b-table
        v-else
        id="allMembers-table"
        hover
        selectable
        :select-mode="selectMode"
        :items="allMembers"
        responsive
        sticky-header="true"
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >
        <!-- Column: User -->
        <template #cell(NAME)="data">
          <b-media vertical-align="center">
            <span
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.user.name }}
            </span>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(EMAIL)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.user.image?data.item.user.image: require('@/assets/images/avatars/User-place-holder.svg')"
              />
            </template>
            <span class="text-nowrap">
              {{ data.item.user.email }}
            </span>
            <b-badge
              v-if="getCurrentLoggedInUser.id === data.item.user.id"
              pill
              variant="success"
              class="badge-glow text-nowrap font-small-3 group-member-pill"
            >You</b-badge>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(INVITATION_STATUS)="data">
          <b-badge
            pill
            :variant="`${resolveUserStatusVariant(data.item.invitation_status)}`"
            class="badge-class d-flex align-items-center"
          />
          <span
            v-b-popover.hover.top="data.item.invitation_status === InvitationStatusType.PENDING ? data.item.is_link_expired ? 'Link expired, please resend invitation link!' : 'Copy link to clipboard!' : ''"
            class="text-capitalize"
            @click="copyLinkToClipboard( data.item.invitation_link)"
          >
            {{ memberStatusTextFun(data.item.invitation_status) }}
          </span>
        </template>
        <!-- Column: Role -->
        <template #cell(ROLE)="data">
          <span class="text-nowrap">
            {{ roleStatusTextFun(data.item.role) }}
          </span>
        </template>
        <!-- Column: Actions -->
        <template #cell(ACTIONS)="data">
          <span class="text-nowrap float-center d-flex justify-content-around">
            <b-dropdown
              v-if="data.item.role !== MemberType.OWNER"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              :disabled="!getSelectedGroupPaymentSubscriptionStatus"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="data.item.invitation_status!==InvitationStatusType.APPROVED"
                :disabled="data.item.invitation_status ===InvitationStatusType.APPROVED"
                @click="resendInvitationToMember(data.item.id)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Resend Invitation</span>
              </b-dropdown-item>
              <b-dropdown-item
                :disabled=" !canEditUpdate(data.item.role)"
                class="mb-0 pb-0"
                @click="updateMemberDataFunc(data.item.id,data.item.permissions, data.item.role )"
              >
                <feather-icon
                  :id="data.item.user.id"
                  icon="EyeIcon"
                  class="cursor-pointer mr-50"
                  size="16"
                />
                <span>Update</span>
              </b-dropdown-item>
              <b-dropdown-item
                :disabled=" !canEditUpdate(data.item.role)"
                @click="deleteMemberFromGroup(data.item.id)"
              >
                <feather-icon
                  :id="data.item.user.id"
                  icon="Trash2Icon"
                  class="cursor-pointer mr-50"
                  size="16"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            /></b-col>
        </b-row>
      </div>
    </b-card>
    <UpgradeDowngradeFeature
      ref="upgrade-downgrade-packages"
      :members="allMembers"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BButton,
  VBToggle,
  BBadge,
  VBPopover,
  BSkeletonTable,
  BDropdown,
  BDropdownItem,
  VBTooltip, BTooltip, BAvatar,

} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import MemberType from '@/common/enums/memberTypeEnum'
import PackageFeatures from '@/common/enums/packagesFeatureTypeEnum'
import InvitationStatusType from '@/common/enums/invitationStatusEnum'
import AddGroupMember from '@/components/group-members/AddGroupMember.vue'
import UpdatePermissionsOfMember from '@/components/group-members/UpdatePermissionsOfMember.vue'
import store from '@/store'
import BreadCrumb from '@/components/common/BreadCrumb.vue'
import VueClipboard from 'vue-clipboard2'
import ToastificationContent from '@/components/common/ToastificationContent.vue'
import { showErrorMessage, showToast, confirmDeletion } from '@/common/global/functions'
import CreateGroupModal from '@/views/groups/CreateGroupModal.vue'
import UpgradeDowngradeFeature from '@/components/features/UpgradeDowngradeFeature.vue'
import PackageType from '@/common/enums/packagesEnum'

Vue.use(VueClipboard)
Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    CreateGroupModal,
    AddGroupMember,
    UpgradeDowngradeFeature,
    UpdatePermissionsOfMember,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BBadge,
    BSkeletonTable,
    BDropdown,
    BDropdownItem,
    BButton,
    BTooltip,
    BAvatar,
    BreadCrumb,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    'b-popover': VBPopover,
    Ripple,
  },
  async beforeRouteEnter(to, from, next) {
    const groupUser = await store.getters['user/getSelectedGroupMember']
    const isUserAdmin = await store.getters['user/isUserAdmin']
    if (!isUserAdmin && groupUser === null) {
      next({
        name: 'pricing-plans',
      })
    } else if (isUserAdmin || groupUser.role === MemberType.MEMBER) {
      next({
        name: '/',
      })
    } else { next() }
  },
  data() {
    return {
      MemberType,
      PackageFeatures,
      InvitationStatusType,
      active: false,
      spinner: false,
      allMembers: [],
      currentUserRole: '',
      updateMember: {
        id: null,
        permissions: [],
        role: null,
        PackageType,
      },
      tableColumns: [
        { key: 'NAME' },
        { key: 'EMAIL' },
        { key: 'INVITATION_STATUS' },
        { key: 'ROLE' },
        {
          key: 'ACTIONS',
          style: 'float: right',
          thClass: 'pull-center text-center textTransform',
        },
      ],
      selectMode: 'single',
      selected: [],
      getPackagesLoader: false,
      selectedGroupId: null,
      breadCrumbItems: [
        { name: 'Home', to: '/', active: false },
        { name: 'Businesses', to: '/groups-listing', active: false },
        { name: 'Applications', to: `/applications?groupId=${this.$route.query.groupId}`, active: false },
        { name: 'Group Members', to: null, active: true },
      ],
      editGroupModal: true,
    }
  },
  computed: {
    getUserId() {
      return this.$store.getters['user/getUserId']
    },
    getSelectedGroupMember() {
      return this.$store.getters['user/getSelectedGroupMember']
    },
    getUserOwnCreatGroup() {
      return this.$store.getters['user/getUserOwnCreatedGroup']
    },
    getMemberRoleInSelectedGroup() {
      return this.getSelectedGroupMember ? this.getSelectedGroupMember.role : null
    },
    getQuotaOfMembersInSelectedGroup() {
      return this.getSelectedGroupMember ? this.getSelectedGroupMember.user_has_group.quota : null
    },
    getSelectedGroupPaymentSubscriptionStatus() {
      return this.getSelectedGroupMember
      && this.getSelectedGroupMember.user_has_group
      && this.getSelectedGroupMember.user_has_group.package
      && this.getSelectedGroupMember.user_has_group.package.isActive ? this.getSelectedGroupMember.user_has_group.package.isActive : null
    },
    getCurrentLoggedInUser() {
      return this.$store.getters['user/getUser']
    },
    canAddMember: {
      get() {
        return this.allMembers.length - 1 < JSON.parse(this.getQuotaOfMembersInSelectedGroup)
      },
      set() {
        //
      },
    },
    isAddFeatureDisabled() {
      if (this.allMembers[0].user_has_group.package.trial_month_count === 0) {
        return false
      }
      return true
    },
    isPowerSaving() {
      if (this.getSelectedGroupMember.user_has_group.package.plan.slug === PackageType.POWER_SAVER) {
        return true
      }
      return false
    },
    isActive: {
      get() {
        return this.getSelectedGroupPaymentSubscriptionStatus
      },
      set() {
        //
      },
    },
    isAgency() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions'].includes(PackageFeatures.AGENCY)
    },
  },
  async mounted() {
    await this.$store.dispatch('user/setAllUserGroupIfNotPresent')
    this.selectedGroupId = this.$route.query.groupId
    await this.getAllMembersOfGroup()
  },
  methods: {
    async cancelSubscription() {
      this.$swal.fire({
        title: 'Do you want to cancel your Subscription',
        text: 'All of your data will be lost',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: ' Cancel',
        reverseButtons: true,
        width: '650px',
      }).then(async result => {
        if (result.isConfirmed) {
          this.spinner = true
          try {
            await this.$axios.put('user-has-group/unsubscribe',
              {
                unsubscribe: true,
              },
              {})
            await this.$store.dispatch('user/setAllUserGroupAfterUnSubscribed')
            await this.$swal.fire({
              title: 'UnSubscribed!',
              text: 'Your Subscription has been cancelled.',
              icon: 'success',
            }).then(async result1 => {
              if (result1.isConfirmed) {
                this.$router.push('/groups-listing')
                this.spinner = false
              }
            })
          } catch ({
            response: {
              data: {
                statusCode,
                message,
              },
            },
          }) {
            showToast('Checkout', message.toString(), 'danger')
          }
        }
      })
    },
    copyLinkToClipboard(linkText) {
      this.$copyText(linkText).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Link copied!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        }, { timeout: 2000 })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      })
    },
    canEditUpdate(role) {
      switch (this.getMemberRoleInSelectedGroup) {
        case MemberType.OWNER:
          return role !== MemberType.OWNER
        case MemberType.SUPER_MEMBER:
          return role === MemberType.MEMBER
        default: return false
      }
    },
    async getAllMembersOfGroup() {
      this.spinner = true
      try {
        const res = await this.$axios.get(`user-has-group/get-user-group-details/${this.selectedGroupId}`)
        const { data: resData } = res || {}
        const { data } = resData || {}
        this.allMembers = data || {}
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        showToast('Business Members', 'You are not authorized for this action!', 'danger')
        await store.dispatch('user/setAllUserGroupIfNotPresent', true)
        await this.$router.push('groups-listing')
      }
      this.spinner = false
    },
    changeState() {
      this.active = false
    },
    resolveUserStatusVariant(Status) {
      if (Status === InvitationStatusType.PENDING) return 'secondary'
      if (Status === InvitationStatusType.APPROVED) return 'success'
      return 'warning'
    },
    memberStatusTextFun(Status) {
      if (Status === InvitationStatusType.PENDING) return 'Pending'
      if (Status === InvitationStatusType.APPROVED) return 'Approved'
      return 'Rejected'
    },
    roleStatusTextFun(Status) {
      if (Status === MemberType.OWNER) return 'Owner'
      if (Status === MemberType.MEMBER) return 'Member'
      return 'Super Member'
    },
    addMemberToGroup() {
      if (this.isPowerSaving && this.getSelectedGroupMember.user_has_group.fiskil_end_user_id === null) {
        this.$swal.fire({
          title: 'Simi Power Saver',
          text: 'Error.',
          icon: 'error',
        })
      } else {
        this.$refs['add-member-modal'].show()
      }
    },
    updateMemberDataFunc(id, permissions, role) {
      this.updateMember.id = id
      this.updateMember.permissions = permissions.map(permission => permission.feature.id)
      this.updateMember.role = role
      this.$refs['update-member-modal'].show()
    },
    deleteMemberFromGroup(id) {
      this.updateMember.id = id
      confirmDeletion().then(async result => {
        if (result.value) {
          this.memberDeleteFromGroup(this.updateMember.id)
        }
      })
    },
    async memberDeleteFromGroup(id) {
      try {
        await this.$axios.delete(`/user-has-group/remove-member-from-group/${id}`)
        await this.getAllMembersOfGroup()
        await store.dispatch('user/setAllUserGroupIfNotPresent', true)
        showToast('Remove Business Member', 'Member removed from this group!')
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Delete Member', 'You are not authorized for this action!', 'danger')
          await store.dispatch('user/setAllUserGroupIfNotPresent', true)
          await this.$router.push('groups-listing')
        }
      }
    },
    async resendInvitationToMember(id) {
      try {
        await this.$axios.post('user-has-group/resend-user-invite', {
          userGroupMemberId: id,
        })
        await this.getAllMembersOfGroup()
        showToast('Resend Invitation', 'Invitation has been resend to member!')
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Resend Invitation', 'You are not authorized for this action!', 'danger')
          await store.dispatch('user/setAllUserGroupIfNotPresent', true)
          await this.$router.push('groups-listing')
        }
      }
    },
    async editGroupDetail() {
      const selectedGroup = this.getUserOwnCreatGroup.find(obj => obj.id === Number(this.selectedGroupId))
      this.$refs['create-group-modal'].show(selectedGroup)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.group-members {
  body {
    line-height: 0 !important;
  }
  .dropdown-item{
    padding-bottom: 5px !important;
  }
   .dropdown-menu{
     padding: 0 !important;
  }
  .b-table-sticky-header{
   overflow-y: auto;
    //min-height: 120px !important;
    max-height: 600px !important;
  }
  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .badge:empty{
display: inline !important;
  }
  .badge{
    padding: 0px !important;
  }
   .badge-pill{
     padding-right: 0.3rem !important;
     padding-left: 0.3rem !important;
   }
  .badge-class{
    height: 5px !important;
    width: 5px !important;
    font-size: 6px !important;
    margin-right: 4px;
  }
  .button-style{
    padding-right: 2rem;
  }
  .group-member-pill{
    padding: 5px !important;
    margin-left: 8px;
  }
}
@media (max-width: 960px) {
  .card-wrapper{
    display: flex;
    flex-direction: column
  }
  .breadcrumb-wrapper{
    width: 100% !important;
  }
  .button-wrapper{
    width: 100% !important;
    display: flex;
    padding-left: 5rem
  }
  .buttons{
    padding: 6px 9px;
    font-size: 12px;
    width: 95px;
}
}

@media (max-width: 560px) {
  .buttons{
    padding: 6px 9px;
    font-size: 8px;
    width: 70px;
}
}
</style>
